

<div class="margin-12-per" id="toggleTabBar">
  <div (click)="showUSDCTab()" class="flex-center-item" [ngClass]="{'tab-active': tabIndex==0}"><p>Pay with USDC</p></div>
  <div (click)="showAssetTab(1)" class="flex-center-item" [ngClass]="{'tab-active': tabIndex==1}" *ngIf="assetTokenAddress"><p>Pay with {{assetSymbol}}</p></div>
  <div (click)="showAssetTab(2)" class="flex-center-item" [ngClass]="{'tab-active': tabIndex==2}" ><p>Insured Wallet</p></div>
</div>

<div class="tab-content margin-12-per">
  <div *ngIf="tabIndex !== 2">
  <div class="row pre-deposit " *ngIf="true" id="buyBox">
    <div class="box-wrap" style="display: flex;gap: 28px;background-color: unset;">
      <div class="column-8 number-box">
        <div class="column-6" style="padding-left: 34px;padding-top: 30px;">
        <p class="title" style="margin: 0;display: flex;">My Predeposit Balance
          <img  (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" />
        </p>
        <div class="float-item" style="top: 35%;">
          <p>
            Weekly coverage cost will be automatically deducted from the pre-deposit balance to save user’s gas. Please keep enough balance to maintain your coverage plan.
          </p>
        </div>
          <p class="number font-36-blue" style="margin-top: 12px;">{{ formatTokenBalance(predepositBalance) }}
            <span class="font-18-blue" *ngIf="tabIndex==0">USDC</span>
            <span class="font-18-blue" *ngIf="tabIndex==1">{{assetSymbol}}</span>
          </p>
        </div>
        <div class="column-3 padding-top-60" style="border-width: 0">
            <a href="javascript:void(0)" (click)="showDeposit()" class="button">Deposit</a>
        </div>
        <div class="column-3 padding-top-60" style="border-width: 0">
          <a href="javascript:void(0)" (click)="showWithdraw()" class="button bgWhite">Withdraw</a>
        </div>
      </div>
      <div class="column-4 number-box" style="display: flex;
      justify-content: center;align-items: center;">
        <div id="coverageBox" style="position: relative;">
          <p class="title" style="display: flex;">Estimate Coverage Period
            <img (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')" src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;"/>
          </p>
          <div class="float-item" style="top: 100%;">
            <p>
              Estimate coverage period is calculated based on your coverage amount and pre-deposit balance. Keep it for few weeks to have a peace of mind.
            </p>
          </div>
          <p class="number" *ngIf="isCovered">{{ weeksBeCovered }} Weeks</p>
          <p *ngIf="!isCovered">Not covered yet</p>
        </div>
      </div>
    </div>
  </div>

  <div class="crypto-list row  " style="padding-top: 27px;">
    <div class="crypto-list-box">
      <div class="crypto-list-item protocol-box">
        <div class="guarantor-number guarantor-number-1">
          <span>Premium Rate (weekly)</span>
          <span class="number" style="font-size:16px">{{formatRate(premiumRate)}}
          </span>
        </div>
        <div class="guarantor-number guarantor-number-2">
          <span>Coverage for Sale</span>
          <span class="number">${{formatTokenBalance(effectiveCapacity)}}</span>
        </div>
        <div class="guarantor-number guarantor-number-3">
          <span>My Covered TVL</span>
          <span class="number">
            <span class="icon-spinner" *ngIf="loading"></span>
            <span class="number"
                *ngIf="!loading&&myCurrentCoverage==myFutureCoverage">${{ formatTokenBalance(myCurrentCoverage) }}
            </span>
            <span class="number"
                *ngIf="!loading&&myCurrentCoverage!=myFutureCoverage">${{ formatTokenBalance(myCurrentCoverage) }} (this week) <br/>
              ${{ formatTokenBalance(myFutureCoverage) }} (next week)
            </span>
          </span>
        </div>
        <div class="guarantor-number guarantor-number-2">
          <span>My Cover Cost (weekly)</span>
          <span class="number">
            <span class="icon-spinner" *ngIf="loading"></span>
            <ng-container>
              <span class="number" *ngIf="!loading&&myCurrentPremium==myFuturePremium">${{ formatTokenBalance(myCurrentPremium) }}</span>
              <span class="number" *ngIf="!loading&&myCurrentPremium!=myFuturePremium">${{ formatTokenBalance(myCurrentPremium) }} (this week) <br/>
                ${{ formatTokenBalance(myFuturePremium) }} (next week)
              </span>
            </ng-container>
          </span>
        </div>
        <div class="guarantor-operation">
          <span (click)="showSubscribe(assetIndex)" class="button" style="font-weight: 600;">
            Adjust
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="crypto-list row">
    <h2 class="title">My Purchase History</h2>
    <div class="crypto-list-box" id="paymentBox">
      <table class="crypto-list-tab" style="width: 100%">
        <tr class="crypto-list-title">
          <td><strong>Date</strong></td>
          <td><strong>Covered TVL (Target)</strong></td>
          <td><strong>Covered TVL (Actual)</strong></td>
          <td><strong>Paid Amount</strong></td>
        </tr>
        <ng-container  *ngFor="let record of records">
          <tr class="crypto-list-item" *ngIf="tabIndex==0">
            <td><span class="number">{{ formatDate(record.blockTime) }}</span></td>
            <td><span class="number">${{ formatTokenBalance(record.futureBase) }}</span></td>
            <td><span class="number">${{ formatTokenBalance(record.currentBase) }}</span></td>
            <td><span class="number">${{ formatTokenBalance(record.premiumBase) }}</span></td>
          </tr>
          <tr class="crypto-list-item" *ngIf="tabIndex==1">
            <td><span class="number">{{ formatDate(record.blockTime) }}</span></td>
            <td><span class="number">${{ formatTokenBalance(record.futureAsset) }}</span></td>
            <td><span class="number">${{ formatTokenBalance(record.currentAsset) }}</span></td>
            <td><span class="number">{{ formatTokenBalance(record.premiumAsset) }}</span>{{assetSymbol}}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>

  <div class="crypto-list row" style="display: flex;">
    <div style="width: 50%; text-align: left;">
      <button class="btn-page" *ngIf="hasFirst()" (click)="goFirst()">First Page</button>
      <button class="btn-page" *ngIf="hasPrev()" (click)="goPrev()">Prev Page</button>
    </div>
    <div style="width: 50%; text-align: right;">
      <button class="btn-page" *ngIf="hasNext()" (click)="goNext()">Next Page</button>
    </div>
  </div>
</div>
<div *ngIf="tabIndex === 2" >
  <div class="row pre-deposit" id="buyBox">
    <div class="box-wrap" style="display: flex;gap: 28px;background-color: unset;">
      <div class="column-12 number-box" style="height: auto;">
        <div style="padding-left: 34px;padding-top: 30px;">
          <p class="title" style="margin: 0 0 16px 0;display: flex;">Payment Account
            <img (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')" 
            src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" />
          </p>
          <div class="float-item" style="top: 35%;">
            <p>
              You can protect multiple accounts with one payment account.
            </p>
            <p>
              During a payout, the damage amount of each account will be analyzed, and compensation will be distributed to each account according to its loss. 
            </p>
            <p>
              If the covered amount is less than the total damaged amount, compensation will be distributed to each account proportionally based on “its own loss / the total loss”. 
            </p>  
          </div>
          <input class="view-input" [(ngModel)]="paymentAccountAddr" style="font-size:16px;font-weight: bold;" disabled>
          <div class="divide-line"></div>
          <div>
            <p class="title" style="margin: 0 0 16px 0;display: flex;">Protected Account
            <img (mouseover)="toggleShowFloatPopup($event, 'visible')" (mouseleave)="toggleShowFloatPopup($event, 'hidden')"  src="/assets/images/info.png" style="width: 20px; height: 20px; margin-left: 8px;" /></p>
            <div class="float-item" style="top: 50%;">
              <p>
                Protect your other accounts (up to 10) with one payment account.
              </p>
              <p>
                The payment account will be protected by default.
              </p>
              <p>
                Accounts logged after the incident do not qualify for payout. Only accounts logged BEFORE the incident will be accounted for payout.
              </p>
            </div>
          </div> 
          <div id="protectedAccountBox" [ngClass]="{'editModeBox': isEditMode}">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address0" value="{{address0}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address1" value="{{address1}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address2" value="{{address2}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address3" value="{{address3}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address4" value="{{address4}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address5" value="{{address5}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address6" value="{{address6}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address7" value="{{address7}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address8" value="{{address8}}" [disabled]="!isEditMode">
            <input class="view-input" placeholder="Please fill account address" [(ngModel)]="address9" value="{{address9}}" [disabled]="!isEditMode">
          </div>
          <div class="btnList">
            <a href="javascript:void(0)" *ngIf="!isEditMode" (click)="toggleLocked()" class="button bgBlue" style="width: 137px;float: right;margin-right: 50px;">Update</a>
            <button class="button font-weight-700 btn-blue" *ngIf="isEditMode" [disabled]="saving" (click)="saveEditResult()">
              Confirm
              <span class="icon-spinner" *ngIf="saving"></span>
            </button>
            <button class="button button-edit btn-white" *ngIf="isEditMode" (click)="isEditMode = false">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>


<app-buy-deposit
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowDeposit"
  [tabIndex]=tabIndex
  (onClose)="closeDeposit()"
  (onRefresh)="refresh()">
</app-buy-deposit>

<app-buy-withdraw
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowWithdraw"
  [tabIndex]=tabIndex
  (onClose)="closeWithdraw()"
  (onRefresh)="refresh()">
</app-buy-withdraw>

<app-buy-subscribe
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0"
  *ngIf="willShowSubscribe"
  [tabIndex]=tabIndex
  [premiumRate]=premiumRate
  (onClose)="closeSubscribe()"
  (onRefresh)="refresh()">
</app-buy-subscribe>

<app-alert-message
  [title]="alertTitle"
  [body]="alertBody"
  style="height: 100%; width: 100%; z-index: 1; position: Fixed; left: 0; top: 0;"
  *ngIf="willShowAlertMessage"
  (onClose)="closeAlert()">
</app-alert-message>
